import React, {Component } from 'react';
import { Formik } from 'formik';
import FormikBasicInput from './forms/FormikBasicInput';
import FormikTextarea from './forms/FormikTextarea';
import * as Yup from 'yup';
import axios from 'axios';
import querystring from 'query-string'
import Recaptcha from 'react-recaptcha';
import { GATSBY_SITE_ID, GATSBY_CONTACT_API_URL, GATSBY_RECAPTCHA_KEY } from './constants';
import { navigate } from "gatsby"


const RECAPTCHA_KEY = GATSBY_RECAPTCHA_KEY;

const SubmitContact = (values) => {
    const instance = axios.create({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    });

    let posted_values = querystring.stringify(values);
    return instance.post(`${GATSBY_CONTACT_API_URL}/${GATSBY_SITE_ID}`,posted_values);
}


class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.validation = Yup.object().shape( {
            first_name: Yup.string().trim().required("This question is required."),
            last_name: Yup.string().trim().required("This question is required."),
            email: Yup.string().email().required("We need a valid email to contact you"),
            company: Yup.string().trim().required("This question is required."),
            title: Yup.string().trim().required("This question is required."),
            message: Yup.string().trim().required("What would you like to speak to us about?"),
            phone_number: Yup.string(),
        });
        this.empty = {
            first_name: '',
            last_name: '',
            email: '',
            company: '',
            title: '',
            message: '',
            phone_number: '',
        };
        this.state = {
            recaptcha: null,
            canSubmit: false,
        };
    }
    verified = (recaptcha) => {
        this.setState({canSubmit: true, recaptcha});
    }
    expired = (values) => {
        this.setState({canSubmit: false, recaptcha: null});
    }

    submit = (values, actions) => {
        const { recaptcha } = this.state;
        SubmitContact({...values, 'g-recaptcha-response': recaptcha})
        .then(response => {
            actions.setSubmitting(false);
            navigate("/thanks");
        })
        .catch( err => {
            console.error(err);
            actions.setSubmitting(false);
        });
    }

    render() {


        const { canSubmit } = this.state;
        let prefix = "rqd";
        return (
            <Formik
                    initialValues={this.empty}
                    validationSchema={this.validation}
                    onSubmit={this.submit}
                    render={(formik) => 
                    {
                        let { handleSubmit, isSubmitting } = formik;
                        return (
                        <div>
                            <form>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <FormikBasicInput
                                        prefix={prefix}
                                        label="First name"
                                        type="text"
                                        name="first_name"
                                        required
                                        formik={formik}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <FormikBasicInput
                                        prefix={prefix}
                                        label="Last name"
                                        type="text"
                                        name="last_name"
                                        required
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <FormikBasicInput
                                        prefix={prefix}
                                        label="Email"
                                        type="email"
                                        name="email"
                                        required
                                        formik={formik}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <FormikBasicInput
                                        prefix={prefix}
                                        label="Phone number"
                                        type="tel"
                                        name="phone_number"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FormikBasicInput
                                        prefix={prefix}
                                        label="Job Title"
                                        type="text"
                                        name="title"
                                        required
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FormikBasicInput
                                        prefix={prefix}
                                        label="Company"
                                        type="text"
                                        name="company"
                                        required
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FormikTextarea
                                        prefix={prefix}
                                        label="Message"
                                        name="message"
                                        formik={formik}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <Recaptcha
                                            verifyCallback={this.verified}
                                            expiredCallback={this.expired}
                                            sitekey={RECAPTCHA_KEY}
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button className="btn btn-success btn-block btn-lg" type="submit" disabled={isSubmitting || !canSubmit} onClick={handleSubmit} >Submit</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    )}}
            />
        )

    }
}

export default ContactForm;