import React, { Component } from 'react'

import { css } from '@emotion/core';
import Helmet from 'react-helmet';

import Stage from '../components/stage';
import Layout from '../components/BSLayout';

import './startup.css';
import ContactForm from '../components/RequestDemoForm';
import DesktopNavbar from '../components/DesktopNavbar';
import MobileNavbar from '../components/MobileNavbar';
import { MinimalFooter } from '../components/Footer';

const mainContent = css`
    padding-top: 0px;
    padding-bottom: 2rem;
    min-height:90vh;
`
    //min-height:85.2vh;
const cssContactHero = css`
    height: 350px;
    padding: 2.5rem 0;
    max-height: 460px;
`

const cssContact = css`
    max-width: 638px;
    margin:auto;
    box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025);
`

const cssOverflow = css`
    margin-top: -10rem;
    padding-top: 2.5rem;
`
const cssPageTitle = css`
    font-size: 4rem;
`

const cssPageSubTitle = css`
    color: rgba(13,17,43,.7);
`

const cssPageSubTitleLink = css`
    color: rgba(13,17,43);
    text-decoration: none;
    &:hover {
        color: #007bff;
        text-decoration: none;
    }
`

const contact_number = "+61 1300 421 542";

const ContactHero = (props) => (
    <div>
        <div css={cssContactHero} className="text-center">
            <h1 css={cssPageTitle} className="h1">Contact Us</h1>
            <h3 css={cssPageSubTitle} className="h3">We're here to help. <br /> Fill out the form bellow  or call us on <a css={cssPageSubTitleLink} href={`tel: ${contact_number}`}>{contact_number}</a></h3>
        </div>
    </div>
)
const Contact = (props) => (
    <div css={cssContact} className="card">
        <div className="card-body">
            <ContactForm />
        </div>
    </div>
)

const HeadData = () => (
  <Helmet>
    <title>Contact Us - Castellan</title>
    <meta name="description" content="Contact us to see how we can help -Castellan" />
    <meta name="keywords" content="smart city, smart apartment, smart condominium, castellan, contact" />
  </Helmet>
)

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  navToggle = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen});
  }

  render() {
    const { menuOpen } = this.state;

    return (
        <Layout>
            <HeadData />
            <MobileNavbar />
            <Stage isOpen={menuOpen}>
                <DesktopNavbar onNavToggle={this.navToggle} />
                <div className="stage">
                <div css={mainContent} >
                    <div>
                            <ContactHero />
                    </div>
                    <div>
                        <div css={cssOverflow} className="container">
                            <Contact />
                        </div>
                    </div>
                </div>
                <div>
                    <MinimalFooter />
                </div>
                </div>
            </Stage>
        </Layout>
    )
}
}
export default Page;