import React from 'react';

function FormikTextarea(props) {

    let { formik, name, label, prefix } = props;
    let id = prefix + "-" + name;
    let rows = (props.rows) ? props.rows : 3;

    let touched = formik.touched[name];
    let error = formik.errors[name];
    let value = formik.values[name];
    let inputClassName = (touched && error) ? "form-control is-invalid" : "form-control";
    return(
        <div className="form-group" >
            <label className="control-label" htmlFor={id}>{label}</label>
            <textarea
                className={inputClassName}
                id={id}
                name={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                text={value}
                rows={rows}
            />
            {touched && error && (<span className="invalid-feedback">{error}</span>)}
        </div>
    );
}

export default FormikTextarea;